
  /* Created with https://www.css-gradient.com */
  
.wrapper {
  margin: 10vh;
}

.card {
  position: relative;
  border: none;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  border-radius: 20px;
  min-height: 325px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .card {
    min-height: 350px;
  }
}
@media (max-width: 420px) {
  .card {
    min-height: 300px;
  }
}
.card.card-has-bg {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: center center;
}
.card.card-has-bg:before {
  content: "";
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}
/* cards on hoverss stylings */
.card.card-has-bg:hover {
  transform: scale(0.95);
  box-shadow: 0 0 5px -2px rgba(143, 166, 10, 0.3);
  background-size: 130%;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}
.card.card-has-bg:hover .card-img-overlay {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  background: #236d42;
  background: linear-gradient(0deg, rgba(7, 135, 56, 0.5) 0%, #855205 100%);
}
.card .card-footer {
  background: none;
  border-top: none;
}
.card .card-footer .media img {
  border: solid 3px rgba(255, 255, 255, 0.3);
}
.card .card-meta {
  color: #26bd75;
}
.card .card-body {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}
.card:hover {
  cursor: pointer;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.card:hover .card-body {
  margin-top: 30px;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  text-decoration: none;
} 
.card-img{
  color: white;
}
.main:hover .card-img{
  font-size: 1.47em;
  /* to hide the text in the card */
  /* display: none; */
}
.card-img:hover{
  color: rgb(6, 249, 99);
  font-weight: bolder;
}
/* to bring the blue mask over the pictures or cards */
/* .card .card-img-overlay {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  background: #234f6d;
  background: linear-gradient(
    0deg,
    rgba(104, 150, 180, 0.379) 0%,
    #455f71 100%
  );
} */

/* img.card-img {
  z-index: 999;
}  */