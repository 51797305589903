.box {
  position: relative;
}

.bgHImg {
  max-height: 500px;
  min-width: 100%;
}

.getStartedBtn {
  background: #dd7448;
}
.getStartedBtn:hover {
  background: linear-gradient(30deg, rgba(7, 135, 56, 0.5) 5%, #855205 100%);
}


.img_title {
  /* margin-top: 17rem; */
  position: absolute;
  color: white;
  text-align: center;
  left: 0;
  right: 5%;
  top: 71%;
  letter-spacing: 3px;
  font-family: "Montez", cursive;
}
